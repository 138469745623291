<template>
  <v-card class="mt-4">
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon :color="color" class="mr-3">{{ icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="font-weight:bold;line-height: 2em">{{ text }}</v-list-item-title>
          <v-list-item-subtitle>{{ course.title }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="status === 'returned'" style="font-weight:bold">Returned to the student.</v-list-item-subtitle>
          <v-list-item-subtitle v-for="{ _id, category, date, name } in approvals" :key="_id">
            <div style="font-weight:bold;width:270px">{{ category + ' Approval' + (name ? ' (' + name + ')' : '') }}: </div>
            <div>
              <v-icon :color="date == null ? 'info' : 'success'" small>fal fa-{{ date == null ? 'question' : 'check' }}-circle</v-icon>
              {{ date == null ? 'Pending' : 'Approved ' + stringFormatDate(date) }}
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions v-if="isApprover && ['submitted', 'pending', 'approved'].includes(status)">
      <template v-if="isRecords">
        <v-btn v-if="status !== 'submitted'" :disabled="!instructorApproved || !advisorApproved" color="success" style="height:56px" @click="approve('Records')">
          <v-icon left>fal fa-check-circle</v-icon>
          <span v-if="instructorApproved && advisorApproved">Mark<br/>Processed</span>
          <span v-else>Awaiting<br/>{{ action === 'add' ? 'Approval' : 'Acknowledgement' }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu v-if="['submitted', 'pending', 'approved'].includes(status) || !instructorApproved || !advisorApproved" left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" outlined small>
              <v-icon small left>fas fa-pencil</v-icon>
              Edit
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="status !== 'processed'" @click="returnToStudent">
              <v-list-item-title>Return to Student</v-list-item-title>
            </v-list-item>
            <v-menu v-if="isRecords && status === 'submitted'" offset-x>
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>Change Approving Advisor</v-list-item-title>
                  <v-list-item-action>
                    <v-icon small>fas fa-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item v-for="{ pidm, name, code } in advisors" :key="pidm" :disabled="pidm === advisorPidm" @click="updateAdvisor(pidm)">
                  <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>Advisor Code: {{ code }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item v-if="isRecords && !instructorApproved" @click="approve('Instructor')">
              <v-list-item-title>{{ action === 'add' ? 'Approve' : 'Acknowledge' }} for Instructor</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isRecords && !advisorApproved" @click="approve('Advisor')">
              <v-list-item-title>{{ action === 'add' ? 'Approve' : 'Acknowledge' }} for Advisor</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-btn v-else-if="pendingApproval" color="success" @click="approve()">
        <v-icon left>fal fa-check-circle</v-icon>
        {{ action === 'add' ? 'Approve' : 'Acknowledge' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    mainId: String,
    changeId: String,
    action: String,
    status: String,
    course: Object,
    crn: String,
    approvals: Array,
    advisors: Array,
    scheduleUpdatedAt: String,
    gradeMode: String
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const icon = computed(() => {
      let text = props.status === 'pending' ? 'fal ' : 'fas '
      switch (props.action) {
        case 'add':
          return text + 'fa-plus-circle'
        case 'drop':
          return text + 'fa-minus-circle'
        case 'change':
          return text + 'fa-exclamation-circle'
      }
    })
    const color = computed(() => {
      switch (props.action) {
        case 'add':
          return 'success'
        case 'drop':
          return 'error'
        case 'change':
          return 'info'
      }
    })
    const text = computed(() => {
      switch (props.action) {
        case 'add':
          return 'Adding Course'
        case 'drop':
          return 'Dropping Course'
        case 'change':
          return 'Changing Course Grade Mode to ' + (props.gradeMode === 'pass-fail' ? 'Pass-Fail' : 'Standard Grading')
      }
    })
    const isCompleted = computed(() => props.approvals.filter(({ date }) => date == null).length === 0)
    const pendingLoad = computed(() => {
      const dt = new Date(props.scheduleUpdatedAt)
      const { date } = props.approvals.find(({ category }) => category === 'Records')
      // If there is a date on the Records approval for this change, then that means they have processed it.
      // If that date is after the last time the schedule was updated, then show it as still pending because that means it hasn't been loaded
      // into the Portal and won't be reflected on the schedule view tabs.
      if (date && new Date(date) > dt) return true
      return false
    })

    const isInstructor = computed(() => props.approvals.filter(({ category, pidm }) => category === 'Instructor' && pidm === user.value.pidm).length > 0)
    const instructorApproved = computed(() => props.approvals.filter(({ category, date }) => category === 'Instructor' && typeof (date) === 'undefined').length === 0)
    const isAdvisor = computed(() => props.approvals.filter(({ category, pidm }) => category === 'Advisor' && pidm === user.value.pidm).length > 0)
    const advisorApproved = computed(() => props.approvals.filter(({ category, date }) => category === 'Advisor' && typeof (date) === 'undefined').length === 0)
    const advisorPidm = computed(() => props.approvals.find(({ category }) => category === 'Advisor').pidm || null)
    const isRecords = computed(() => roles.value.includes('Records') || roles.value.includes('Technology Services'))
    const recordsProcessed = computed(() => props.approvals.filter(({ category, date }) => category === 'Records' && typeof (date) === 'undefined').length === 0)
    const isApprover = computed(() => isRecords.value || isAdvisor.value || isInstructor.value)

    const pendingApproval = computed(() => (isInstructor.value && !instructorApproved.value) || (isAdvisor.value && !advisorApproved.value))

    const service = root.$feathers.service('student/schedule-change')
    async function approve (category) {
      let text = category
      if (category == null) {
        if (isInstructor.value && isAdvisor.value) text = 'Instructor and Advisor'
        else if (isInstructor.value) text = 'Instructor'
        else if (isAdvisor.value) text = 'Advisor'
      }
      let obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Course Change ' + text + (props.action === 'add' ? ' Approved' : ' Acknowledged') + ': ' + props.course.title,
        icon: 'fal fa-check',
        color: 'success',
        visibleToStudent: true
      }
      if (category === 'Records' || (category == null && isRecords.value)) {
        obj.text = 'Course change processed in Banner: ' + props.course.title
        // Push the processing notice to the timeline
        // Add a pull command to remove all matching entries from the changes array (we can only have one active entry with the same action on the same course)
        const patch = {
          $push: { timeline: obj },
          $pull: { changes: { action: props.action, crn: props.crn } }
        }
        // We need to move the change from the changes array to the completed array, so we need to load the change records so we can have the full record to move
        const { changes } = await service.get(props.mainId)
        // Find the record which matches the action and CRN of this change
        const rec = changes.find(({ action, crn }) => action === props.action && crn === props.crn)
        // Make sure the matching record was found (just in case two people try to do the same update at the same time)
        if (rec != null) {
          // Update the Records approval to have the current date and the person who completed it
          rec.approvals.map((row) => {
            if (row.category === 'Records') {
              row.pidm = user.value.pidm
              row.date = new Date()
            }
            return row
          })
          // Push the updated change record to the completed array
          patch.$push.completed = rec
          // Do the update here (since we don't need the arrayFilters we are running it here)
          await service.patch(props.mainId, patch)
        }
      } else {
        const patch = {
          $push: { timeline: obj },
          'changes.$[chg].approvals.$[appr].date': new Date()
        }
        if ((category === 'Instructor' && advisorApproved.value) || (category === 'Advisor' && instructorApproved.value) || text === 'Instructor and Advisor') {
          patch['changes.$[chg].status'] = 'approved'
        }
        // Build the array filters so we know which array item to update
        // The first thing in the array filter is which change request to update; this is based on the CRN and action
        const arrayFilters = [{ 'chg.action': props.action, 'chg.crn': props.crn }]
        // Next, we need to know which approval to update; this is either based on the category (if passed), or anything set to be approved by the current user (if not category)
        // If no category, approve all for this person (so if they are both the instructor and advisor it will do both)
        if (category == null) arrayFilters.push({ 'appr.pidm': user.value.pidm })
        // Otherwise update based on the category (this is if the Records office is overriding the approval for a specific category)
        else arrayFilters.push({ 'appr.category': category })
        // Run the patch
        const { changes, _id, bannerId, name } = await service.patch(props.mainId, patch, { query: { arrayFilters } })
        const { approvals } = changes.find(({ action, crn }) => action === props.action && crn === props.crn)
        if (!roles.value.includes('Records') && approvals.filter(({ category, date }) => category !== 'Records' && date == null).length === 0) {
          // Email the Records office to notify that the advisor and instructor have both approved this change
          await root.$feathers.service('system/email').create({ to: 'recordsoffice@covenant.edu', subject: 'Schedule Change Ready for Processing', html: `A schedule change for ${name} (${bannerId}) has been approved by both the instructor and advisor and is ready to be processed in Banner.<br/><a href="https://portal.covenant.edu/student/schedule/${_id}">View This Change Request</a>.` })
        }
      }
    }

    async function returnToStudent () {
      let obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Course Change Returned to Student: ' + props.course.title,
        icon: 'fal fa-check',
        color: 'success',
        visibleToStudent: true
      }
      const patch = {
        $push: { timeline: obj },
        'changes.$[chg].status': 'returned'
      }
      const arrayFilters = [{ 'chg.action': props.action, 'chg.crn': props.crn }]
      await service.patch(props.mainId, patch, { query: { arrayFilters } })
    }

    async function updateAdvisor (pidm) {
      const patch = {
        'changes.$[chg].approvals.$[appr].pidm': pidm
      }
      const arrayFilters = [{ 'chg.action': props.action, 'chg.crn': props.crn }, { 'appr.category': 'Advisor' }]
      await service.patch(props.mainId, patch, { query: { arrayFilters } })
    }

    return {
      icon,
      color,
      text,
      isCompleted,
      pendingLoad,
      isInstructor,
      instructorApproved,
      isAdvisor,
      advisorApproved,
      advisorPidm,
      isRecords,
      recordsProcessed,
      approve,
      isApprover,
      pendingApproval,
      returnToStudent,
      updateAdvisor,
      stringFormatDate
    }
  }
}
</script>
